
import { defineComponent } from "vue";
import EditCoinEntryForm from "@/views/coins/constituent/EditCoinEntryForm.vue";

export default defineComponent({
  name: "EditCoinEntry",
  components: {
    EditCoinEntryForm,
  },
  setup() {
    return {};
  },
});
