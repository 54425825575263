<template>
  <div class="row justify-content-center my-5">
    <div class="card col-12 col-md-6 col-lg-4">
      <DualRingLoader :loading="loading">
        <div class="card-body">
          <form v-if="loaded" class @submit.prevent="Submit">
            <div class="mb-3">
              <div>
                <label for="name" class="form-label">Name</label>
                <InputWrapper :errors="formError.name">
                  <input
                    type="text"
                    v-model="formData.name"
                    class="form-control"
                    id="name"
                    required
                  />
                </InputWrapper>
              </div>
            </div>
            <div class="mb-3">
              <div>
                <label for="code" class="form-label">Code</label>
                <InputWrapper :errors="formError.code">
                  <input
                    type="text"
                    v-model="formData.code"
                    class="form-control"
                    id="code"
                    disabled
                  />
                </InputWrapper>
              </div>
            </div>

            <div class="mb-3">
              <div>
                <label for="usd_rate" class="form-label">Rate</label>
                <InputWrapper :errors="formError.usdRate">
                  <input
                    type="number"
                    v-model="formData.usdRate"
                    class="form-control"
                    id="usd_rate"
                    step="any"
                    required
                  />
                </InputWrapper>
              </div>
            </div>

            <div class="text-center">
              <button class="btn btn-dark">Update Coin Entry</button>
            </div>
          </form>
          <div v-else>
            <h1 class="text-center">Unable to fetch entry</h1>
            <div class="text-center">
              <button
                type="button"
                class="btn btn-sm btn-outline-primary"
                @click="RefreshData"
              >Retry</button>
            </div>
          </div>
        </div>
      </DualRingLoader>
    </div>
  </div>
</template>
  
  <script lang="ts">
import { defineComponent, onBeforeMount, ref } from "vue";
import { apiGet, apiPut } from "@/api";
import DualRingLoader from "@/components/loader/DualRingLoader.vue";
import { Toast } from "@/utils/notify";
import { useRoute } from "vue-router";
import { ShowSuccess } from "@/utils/notify";
import InputWrapper from "@/components/InputWrapper.vue";

export default defineComponent({
  name: "EditCoinEntryForm",
  components: { InputWrapper, DualRingLoader },
  emits: ["save"],
  setup() {
    const formData = ref({
      code: null,
      usdRate: null,
      name: null,
    });
    const loaded = ref(false);
    const formError = ref({});
    const route = useRoute();
    const loading = ref(false);
    async function Submit() {
      loading.value = true;
      try {
        await apiPut(`/admin/coin/type/${route.params.coinId}`, formData.value);
        ShowSuccess("Entry Updated Successfully");
      } catch (e) {
        formError.value = e?.response?.data?.i ?? {};
        Toast.fire({
          icon: "warning",
          title: "Unable to create entry: " + e.message,
        }).then();
      }
      loading.value = false;
    }

    const RefreshData = async () => {
      loading.value = true;
      try {
        const response = await apiGet(
          `/admin/coin/${route.params.coinId}/coin-types`
        );

        const entry = response.data.data.coinType;
        formData.value.code = entry.code;
        formData.value.name = entry.name;
        formData.value.usdRate = entry.usd_rate;

        loaded.value = true;
        Toast.fire({
          icon: "success",
          title: "Card Entry fetched",
        }).then();
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to fetch data: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    onBeforeMount(RefreshData);
    return {
      Submit,
      RefreshData,
      loaded,
      formData,
      formError,
      loading,
    };
  },
});
</script>
  
  <style scoped></style>
  